import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    Button, Modal, TextField, Typography, Box, IconButton
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { styled } from '@mui/system';


// Custom styled components for modal
const ModalStyle = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: 24,
    padding: theme.spacing(4),
}));

const Header = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
}));

const EmailTemplatePage = () => {
    const [templates, setTemplates] = useState([]);  // Initialize templates as an empty array
    const [openModal, setOpenModal] = useState(false);
    const [editingTemplate, setEditingTemplate] = useState(null);
    const [templateData, setTemplateData] = useState({ name: '', subject: '', html_content: '' });

    useEffect(() => {
        const fetchTemplates = async () => {
            try {
                const response = await axios.get('/api/sendgrid/templates');
                console.log('SendGrid API Response:', response.data);  // Log API response

                if (response.data && response.data.templates) {
                    setTemplates(response.data.templates);  // Set templates if they exist
                } else {
                    console.error('No templates found in response');
                    setTemplates([]);  // Set empty array if no templates
                }
            } catch (error) {
                console.error('Error fetching templates:', error);
                setTemplates([]);  // Set empty array in case of error
            }
        };
        fetchTemplates();
    }, []);


    // Handle opening and closing of modal
    const handleOpenModal = (template = null) => {
        setEditingTemplate(template);
        if (template) {
            setTemplateData({
                name: template.name,
                subject: template.subject,
                html_content: template.html_content
            });
        } else {
            setTemplateData({ name: '', subject: '', html_content: '' });
        }
        setOpenModal(true);
    };

    const handleCloseModal = () => setOpenModal(false);

    // Handle input changes in the form
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setTemplateData((prev) => ({ ...prev, [name]: value }));
    };

    // Create or update template
    const handleSubmit = async () => {
        try {
            if (editingTemplate) {
                // Update existing template
                await axios.put(`/api/sendgrid/templates/${editingTemplate.id}/versions/${editingTemplate.active_version.id}`, templateData);
            } else {
                // Create new template
                await axios.post('/api/sendgrid/templates', templateData);
            }
            // Refresh templates after submission
            const response = await axios.get('/api/sendgrid/templates');
            setTemplates(response.data.templates);
            handleCloseModal();
        } catch (error) {
            console.error('Error submitting template:', error);
        }
    };

    // Handle deleting a template
    const handleDelete = async (templateId) => {
        try {
            await axios.delete(`/api/sendgrid/templates/${templateId}`);
            // Refresh templates after deletion
            const response = await axios.get('/api/sendgrid/templates');
            setTemplates(response.data.templates);
        } catch (error) {
            console.error('Error deleting template:', error);
        }
    };

    return (
        <Box sx={{ padding: 3 }}>
            <Header>
                <Typography variant="h4" component="h1">Email Templates</Typography>
                <Button variant="contained" color="primary" onClick={() => handleOpenModal()}>Create New Template</Button>
            </Header>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>Name</strong></TableCell>
                            <TableCell><strong>Subject</strong></TableCell>
                            <TableCell><strong>Actions</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {templates && templates.length > 0 ? (
                            templates.map((template) => (
                                <TableRow key={template.id}>
                                    <TableCell>{template.name}</TableCell>
                                    <TableCell>{template.subject || 'N/A'}</TableCell>
                                    <TableCell>
                                        <IconButton color="primary" onClick={() => handleOpenModal(template)}>
                                            <Edit />
                                        </IconButton>
                                        <IconButton color="secondary" onClick={() => handleDelete(template.id)}>
                                            <Delete />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={3} align="center">
                                    No templates found.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Modal for Create/Edit Template */}
            <Modal open={openModal} onClose={handleCloseModal}>
                <ModalStyle>
                    <Typography variant="h6">{editingTemplate ? 'Edit Template' : 'Create New Template'}</Typography>
                    <TextField
                        margin="normal"
                        label="Template Name"
                        name="name"
                        value={templateData.name}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    <TextField
                        margin="normal"
                        label="Subject"
                        name="subject"
                        value={templateData.subject}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    <TextField
                        margin="normal"
                        label="HTML Content"
                        name="html_content"
                        value={templateData.html_content}
                        onChange={handleInputChange}
                        fullWidth
                        multiline
                        rows={4}
                    />
                    <Box mt={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={handleCloseModal} sx={{ marginRight: 2 }}>Cancel</Button>
                        <Button variant="contained" color="primary" onClick={handleSubmit}>
                            {editingTemplate ? 'Update Template' : 'Create Template'}
                        </Button>
                    </Box>
                </ModalStyle>
            </Modal>
        </Box>
    );
};

export default EmailTemplatePage;

